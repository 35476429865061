import { action, computed, observable, reaction } from 'mobx';
import { Subject } from 'rxjs';
import BlockChangeHistoryModel from '../types/BlockChangeHistoryModel';
import { ProjectsRootVisualStore } from '../../common/stores';

const formTypesTreeExpandedKey = 'formTypesTreeExpanded';

export default class FormTypesVisualStore {
    @observable
    isFormTypeCreateDialogVisible: boolean = false;

    @observable
    isFormVariationCreateDialogVisible: boolean = false;

    @observable
    isFormTemplateRegionCreateDialogVisible: boolean = false;

    @observable
    isFormPartCreateDialogVisible: boolean = false;

    @observable
    isFormBlockCreateDialogVisible: boolean = false;

    @observable
    isFormTypeEditDialogVisible: boolean = false;
    
    @observable
    isFormVariationEditDialogVisible: boolean = false;

    @observable
    isFormRegionEditDialogVisible: boolean = false;

    @observable
    treeExpandedKeys: string[] = [];

    @observable
    showAnnotations: boolean = true;

    @observable
    showSaveButton: boolean = false;

    @observable
    changesHistory: BlockChangeHistoryModel[] = [];

    @observable
    coordinateDelta: number = 0;

    pageChangeSubject: Subject<number> = new Subject();

    @computed
    get arrowkeysStep() {
        if (this.coordinateDelta > 100) {
            return 5;
        }

        if (this.coordinateDelta > 20) {
            return 3;
        }

        return 1;
    }

    constructor(private projectsRootStore: ProjectsRootVisualStore) {
        reaction(
            () => this.projectsRootStore.currentProject,
            () => {
                if (!this.projectsRootStore.currentProject) {
                    return;
                }

                const formTypesTreeExpanded = this.getStoredFormTypesTreeExpanded();

                if (formTypesTreeExpanded && formTypesTreeExpanded.projectId !== this.projectsRootStore.currentProject.id) {
                    this.setTreeExpandedKeys([]);
                }
            }
        ); 
    }

    @action.bound
    toggleAnnotations() {
        this.showAnnotations = !this.showAnnotations;
    }

    @action.bound
    setIsFormTypeCreateDialogVisible(isVisible: boolean) {
        this.isFormTypeCreateDialogVisible = isVisible;
    }

    @action.bound
    setIsFormVariationCreateDialogVisible(isVisible: boolean) {
        this.isFormVariationCreateDialogVisible = isVisible;
    }

    @action.bound
    setIsFormPartCreateDialogVisible(isVisible: boolean) {
        this.isFormPartCreateDialogVisible = isVisible;
    }

    @action.bound
    setIsFormTemplateRegionCreateDialogVisible(isVisible: boolean) {
        this.isFormTemplateRegionCreateDialogVisible = isVisible;
    }

    @action.bound
    setIsFormBlockCreateDialogVisible(isVisible: boolean) {
        this.isFormBlockCreateDialogVisible = isVisible;
    }

    @action.bound
    setIsFormTypeEditDialogVisible(isVisible: boolean) {
        this.isFormTypeEditDialogVisible = isVisible;
    }

    @action.bound
    setIsFormVariationEditDialogVisible(isVisible: boolean) {
        this.isFormVariationEditDialogVisible = isVisible;
    }

    @action.bound
    setIsFormRegionEditDialogVisible(isVisible: boolean) {
        this.isFormRegionEditDialogVisible = isVisible;
    }

    @action.bound
    setTreeExpandedKeys(keys: string[]) {
        this.treeExpandedKeys = keys;

        if (!this.projectsRootStore.currentProject) {
            return;
        }

        sessionStorage.setItem(formTypesTreeExpandedKey, JSON.stringify({ projectId: this.projectsRootStore.currentProject.id, keys }));
    }

    @action.bound
    setShowSaveButton(show: boolean) {
        this.showSaveButton = show;
    }

    @action.bound
    setBlockChangesHistory(history: BlockChangeHistoryModel[]) {
        this.changesHistory = history;
    }

    @action.bound
    addBlockChangeHistory(history: BlockChangeHistoryModel) {
        if (this.changesHistory.length >= 10) {
            this.changesHistory.shift();
        }

        this.changesHistory.push(history);
    }

    @action.bound
    getPreviousHistoryItem() {
        if (this.changesHistory.length <= 1) {
            return this.changesHistory[0];
        }

        return this.changesHistory.pop();
    }

    @action.bound
    setCoordinateDelta(delta: number) {
        this.coordinateDelta = delta;
    }
    
    @action.bound
    changePage(page: number) {
        this.pageChangeSubject.next(page);
    }

    getStoredFormTypesTreeExpanded() {
        const formTypesTreeExpanded = sessionStorage.getItem(formTypesTreeExpandedKey);

        return formTypesTreeExpanded ? JSON.parse(formTypesTreeExpanded) : null;
    }
}