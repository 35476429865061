import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteChildrenProps } from 'react-router';
import { FlexContentContainer } from '../../common/components';
import { FormPartPreview } from '../components';
import { FormTypesStores } from '../stores';
import { STORE_PROJECT_FORM_PART_PREVIEW } from '../stores/constants';

type Props = FormTypesStores & RouteChildrenProps;

const FormPartPreviewPage: React.FC<Props> = ({match, projectFormPartPreviewUI}) => {
    const projectIdKey = 'projectId';
    const formPartIdKey = 'formPartId';

    if (!projectFormPartPreviewUI) {
        return null;
    }

    return (
        <FlexContentContainer 
            content={<FormPartPreview projectId={match?.params?.[projectIdKey]} formPartId={match?.params?.[formPartIdKey]} store={projectFormPartPreviewUI} />} 
        />
    );
};

export default inject(STORE_PROJECT_FORM_PART_PREVIEW)(observer(FormPartPreviewPage));