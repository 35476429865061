import { Alert, Button, Collapse, Layout, Skeleton, Table, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { uniqBy } from 'lodash';
import { Utils } from '../../common/services/Utils';
import LayoutHeader from '../../../components/LayoutHeader';
import { TestProjectPackageActions } from '.';
import { TestProjectDashboardStore } from '../stores';
import { TestRunTopic } from '../types';
import { ColumnType } from 'antd/lib/table';
import TestProjectSortingActions from './TestProjectSortingActions/TestProjectSortingActions';
import { Constants } from '../misc';

type Props = {
    store: TestProjectDashboardStore
};

const TestProjectRunResultList: React.FC<Props> = ({store}) => {
    const [packageSortDirection, setPackageSortDirection] = React.useState<'ascend' | 'descend'>('ascend');
    const [packageSortField, setPackageSortField] = React.useState<string>(Constants.PACKAGE_SORT_FIELD_NAME);

    React.useEffect(() => {
        if (store.currentTestRunId) {
            store.loadTestRunResults();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentTestRunId]);

    React.useEffect(() => {
        const readyToLoad = store.currentProject && store.currentTestProjectId;
        const shouldLoadTestProject = !store.testProject || store.testProject.id !== store.currentTestProjectId;

        if (readyToLoad && shouldLoadTestProject) {
            store.loadTestProject();
        }

        if (readyToLoad) {
            store.loadTestProjectBaselines();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentProject, store.currentTestProjectId]);

    const packageListItems = React.useMemo(() => {
        const testResults = store.currentTestRunResults ? store.currentTestRunResults.topics.slice() : [];
        
        const uniqField = testResults.every((t) => t.packageId != null) ? 'packageId' : 'packageName';
        let items = uniqBy(testResults, uniqField)
            .map(t => ({ packageId: t.packageId, packageName: t.packageName }));

        if (packageSortField === Constants.PACKAGE_SORT_FIELD_NAME) {
            items = items.sort((a, b) => a.packageName.localeCompare(b.packageName));
        }

        // Don't sort in case sort by date is needed, because package ids are stored in the same order as they were loaded

        if (packageSortDirection === 'descend') {
            items = items.reverse();
        }

        return items;
    }, [store.currentTestRunResults, packageSortField, packageSortDirection]);


    if (!store.currentTestRunResults) {
        return null;
    }

    const getTitle = () => {
        if (store.testResultIsLoading || !store.testProject?.name || !store.currentTestRunResults) {
            return <Skeleton active paragraph={{rows: 0}} />;
        }

        return `${store.testProject?.name} - ${Utils.formatDateStringShort(store.currentTestRunResults.runTime)} run results`;
    };

    const loadingContent = (
        <Skeleton active paragraph={{rows: 4}} title={{style: {display: 'none'}}}/>
    );

    const getRunPreviewButton = (topic: TestRunTopic) => {
        if (!topic.metaFields || topic.metaFields.length === 0) {
            return null;
        }

        return (
            <div>
                <Tooltip title="View results in document">
                    <Button 
                        type="link" 
                        icon={<i className="alpha-icon xs form-anchor" style={{backgroundSize: 'contain'}} />} 
                        onClick={() => store.openPreviewTopicResultPreviewWindow(topic.packageId, `${topic.packageId}-${topic.name}`, topic.metaFields)} 
                    />
                </Tooltip>
            </div>
        );
    };

    const runContentForPackage = (packageName: string, packageId: string | null) => {
        if (!store.currentTestRunResults) {
            return null;
        }

        const columns: ColumnType<TestRunTopic>[] = [{
            title: 'Topic',
            dataIndex: 'name',
            key: 'name'

        }, {
            title: 'Baseline',
            dataIndex: 'baseline',
            key: 'baseline',
            render: (baseline: string) => <span style={{whiteSpace: 'pre'}}>{baseline}</span>
        }, {
            title: 'Result',
            dataIndex: 'result',
            key: 'result',
            render: (result: string, record: TestRunTopic) => {
                const color = record.fuzzy >= record.baselineFuzzy ? 'black' : 'red';
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{color: color, whiteSpace: 'pre', flex: '1 1'}}>
                            {result}
                        </div>
                        {getRunPreviewButton(record)}
                    </div>
                );
            }
        }, {
            title: 'Baseline Fuzzy %',
            dataIndex: 'baselineFuzzy',
            key: 'baselineFuzzy',
            render: (fuzzy: number) => fuzzy != null ? `${fuzzy}%` : 'N/A'
        }, {
            title: 'Result Fuzzy %',
            dataIndex: 'fuzzy',
            key: 'fuzzy',
            render: (fuzzy: number) => fuzzy != null ? `${fuzzy}%` : 'N/A'
        }];

        let topics: TestRunTopic[];

        // If packageId is present, use it to filter topics, otherwise use packageName. For backwards compatibility.
        const allResultsHavePackageId = store.currentTestRunResults.topics.every((t) => t.packageId != null);
        if (allResultsHavePackageId && packageId != null) {
            topics = store.currentTestRunResults.topics.filter((t) => t.packageId === packageId).sort((a, b) => a.name.localeCompare(b.name));
        } else {
            topics = store.currentTestRunResults.topics.filter((t) => t.packageName === packageName).sort((a, b) => a.name.localeCompare(b.name));
        }

        return (
            <Table
                columns={columns}
                dataSource={topics}
                pagination={false}
                rowKey={(record: TestRunTopic) => record.packageName + record.name}
            />
        );
    };

    const getRunPanelHeader = (packageName: string) => {
        if (!store.currentTestRunResults) {
            return packageName;
        }

        const hasFailedtopics = store.currentTestRunResults.topics.filter((t) => t.packageName === packageName).some((t) => t.fuzzy < t.baselineFuzzy);

        if (hasFailedtopics) {
            return <span style={{color: 'red', fontWeight: 500}}>{packageName}</span>;
        }

        return <span style={{fontWeight: 500}}>{packageName}</span>;
    };

    const runsContent = () => {
        return (
            <>
                {store.currentTestRunResults?.error && (
                    <Alert
                        style={{marginBottom: 24}}
                        message="Error occurred during test project run"
                        description={store.currentTestRunResults.error}
                        type="error"
                        showIcon
                    />
                )}
                <Collapse className="collapse-with-sticky-header darker">
                    {packageListItems.map(p => (
                        <Collapse.Panel
                            key={p.packageId ?? p.packageName}
                            header={getRunPanelHeader(p.packageName)}
                            extra={
                                <TestProjectPackageActions
                                    packageId={p.packageId}
                                    packageName={p.packageName}
                                    store={store}
                                />
                            }
                        >
                            {runContentForPackage(p.packageName, p.packageId)}
                        </Collapse.Panel>
                    ))}
                </Collapse>
            </>
        );
    };

    return(
        <Layout className="screen-size test-project-dashboard" style={{...{height: '100%', background: 'white'}}}>
            <LayoutHeader  
                title={getTitle()}
                buttons={[
                    <Button 
                        key="test-projects-baselines-go-to-list" 
                        data-id="button-go-to-list" 
                        className="light"
                        size="large"
                        onClick={store.goBackToList}
                    >
                        Go to list
                    </Button>,
                    <Button 
                        key="test-projects-baselines-go-to-dashboard" 
                        data-id="button-go-to-dashboard" 
                        className="light"
                        size="large"
                        onClick={store.goToDashboard}
                    >
                        Go to dashboard
                    </Button>
                ]}
            />
            <Layout>
                <TestProjectSortingActions 
                    fields={Constants.PACKAGE_SORT_FIELDS}
                    fieldName={packageSortField}
                    setFieldName={setPackageSortField}
                    setSortingDirection={setPackageSortDirection} 
                    sortingDirection={packageSortDirection} 
                    storageKey={Constants.PACKAGE_SORT_DIRECTION_STORAGE_KEY} 
                />
                <Layout.Content style={{maxHeight: 'calc(100vh - 100px)', overflow: 'auto'}}>
                    {store.testResultIsLoading ? loadingContent : runsContent()}
                </Layout.Content>
            </Layout>
        </Layout>
    );
};

export default observer(TestProjectRunResultList);