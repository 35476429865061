import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip, Input, Modal, Button, Tree, Form } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { ConnectionsDefinitionCreateModel } from '../types';
import { IotaConnStores } from '../stores';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { IotaApplication } from '../../iota_applications/types';

const { TreeNode } = Tree;

type Props =  IotaConnStores;
export const AddConnectionsDefinition: React.FC<Props> = observer(({ projectConnectionsDefinitionsUI: store }) => {
    const connDefStore = store!;
    const [form] =  Form.useForm();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [connections, setConnections] = React.useState([]);

    React.useEffect(() => {
        connDefStore.loadIotaInputsMetadata();
    },              [connDefStore]);

    React.useEffect(() => {
        if (connDefStore.addDefinitionDialogVisible === false) {
            form.resetFields();
        }
    }, [connDefStore.addDefinitionDialogVisible, form]);

    const handleSubmit = async (e: React.FormEvent<object>) => {
        e.preventDefault();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        form.validateFields()
            .then(async (values) => {
                const model = new ConnectionsDefinitionCreateModel(
                    values.name, 
                    values.applicationId, 
                    values.workflowId, 
                    store!.currentProject!.id, 
                    JSON.stringify(store!.selectedSettingsMetadata!),
                    values.extension,
                    JSON.parse(JSON.stringify(connections))
                );
                await store!.createDefinition(model);    
                form.resetFields();
            })
            .catch((errorInfo) =>  {
                form.scrollToField(errorInfo.errorFields[0].name);
            });
  
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const confirmNameIsUnique = (rule: any, value: any, callback: any) => {
        if (value && !store!.checkIfNameIsUnique(value)) {
            callback('Name is not unique!');
        }
        callback();
    };

    const handleCancel = () => {
        store!.setAddDefinitionDialogVisible(false);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSelect = (selectedKeys: string[], e: any) => {
        form.setFieldsValue({ workflowId: selectedKeys[0] });
        setConnections(e.node.props['data-connections']);

        if (e.selectedNodes && e.selectedNodes.length > 0) {
            form.setFieldsValue({ applicationId: e.node['data-appid'], extension: e.node['data-extname']  });
            const extName = e.node['data-extname'];
            const extVersion = e.node['data-extver'];

            var extensions = store!.iotaExtensions.filter(ext => ext.name === extName && ext.version === extVersion);

            if (extensions && extensions.length > 0) {
                store!.setSelectedSettingsMetadata(extensions[0].settings);
            }
        }
    };

    const appHasConnections = (app: IotaApplication) => !!(app.connections && app.connections.length > 0);

    return (
        <Modal
            title="New connection definition"
            visible={store!.addDefinitionDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            destroyOnClose
            className="alpha-portal-dialog"
            closable={false}
            centered
            footer={[
                <Button data-id="iota-add-connection-dialog-cancel" key="back" onClick={handleCancel} className="light" size="large">
                  Cancel
                </Button>,
                <Button data-id="iota-add-connection-dialog-submit" key="submit" type="primary" onClick={handleSubmit} size="large">
                    Submit
                </Button>
            ]}
        >
            <Form 
                form={form}
                layout="vertical"
                data-id="iota-add-connection-dialog-form"
            >
                <FormItem
                    label={(
                        <span>
                            Definition name&nbsp;
                            <Tooltip title="Definition name for alpha">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    )}
                    name="name"
                    rules={[
                        { required: true, message: 'Please input definition name!', whitespace: true },
                        { validator: confirmNameIsUnique }
                    ]}
                >
                    <Input />
                </FormItem>

                <FormItem
                    label={(
                        <span>
                            Workflow&nbsp;
                            <Tooltip title="Workflow for alpha">
                                <QuestionCircleOutlined/>
                            </Tooltip>
                        </span>
                    )}
                    name="workflowId"
                    rules={[{ required: true, message: 'Please select an application!', whitespace: true }]}
                >       
                    <Tree 
                        className="alpha-portal-tree without-line with-leaf-icons sm-labels" 
                        data-id="iota-add-connection-dialog-workflow-tree" 
                        showLine 
                        onSelect={onSelect}
                    >
                        {store!.iotaExtensions && store!.iotaExtensions.filter(e => e.applications.some(appHasConnections)).map(e => (
                            // eslint-disable-next-line max-len
                            <TreeNode style={{paddingLeft: 'unset'}} data-id={`iota-add-application-dialog-workflow-tree-${e.name}`} title={e.name} selectable={false} key={e.name}>
                                {e.applications.filter(appHasConnections).map(a => (
                                    <TreeNode 
                                        data-id={`iota-add-application-dialog-workflow-tree-${e.name}-${a.id}`}
                                        title={a.name} 
                                        key={a.id} 
                                        selectable={a.connections && a.connections.length > 0} 
                                        data-connections={a.connections}
                                        data-appid={a.id} 
                                        data-extname={e.name}
                                        data-extver={e.version}
                                        switcherIcon={<i className="alpha-icon md document-icon" />}
                                    >
                                        {(a.connections || []).map(w => (
                                            <TreeNode 
                                                title={w.name} 
                                                key={w.id}
                                                selectable={false}
                                            />
                                        ))}
                                    </TreeNode>
                                ))}
                            </TreeNode>
                        ))}
                    </Tree>
                </FormItem>
                <FormItem name="applicationId" style={{display: 'none'}}>
                    <Input type="hidden" />
                </FormItem>
                <FormItem name="extension" style={{display: 'none'}}>
                    <Input type="hidden" />
                </FormItem>
            </Form>
        </Modal>
    );
});

export default AddConnectionsDefinition;