import { ProjectsService } from '../../common/services';
import { AdministrationService } from '../../project_management/services/AdministrationService';
import { STORE_PROJECTS_ROOT_UI } from '../../common/constants';
import { RootStores } from '../../common/stores';
import { FormTypesService } from '../services';
import { STORE_PROJECT_FORM_PART_PREVIEW, STORE_PROJECT_FORM_TYPES, STORE_PROJECT_FORM_TYPES_VISUAL } from './constants';
import FormTypesStore from './FormTypesStore';
import FormTypesVisualStore from './FormTypesVisualStore';
import FormPartPreviewVisualStore from './FormPartPreviewVisualStore';

export { default as FormTypesStore } from './FormTypesStore';
export { default as FormTypesVisualStore } from './FormTypesVisualStore';
export { default as FormPartPreviewVisualStore } from './FormPartPreviewVisualStore';

export function injectFormTypesStores(obj: RootStores) {
    const service = new FormTypesService();
    const projectsService = new ProjectsService();
    const adminService = new AdministrationService();
    obj[STORE_PROJECT_FORM_TYPES] = new FormTypesStore(obj[STORE_PROJECTS_ROOT_UI], service, projectsService, adminService);
    obj[STORE_PROJECT_FORM_TYPES_VISUAL] = new FormTypesVisualStore(obj[STORE_PROJECTS_ROOT_UI]);
    obj[STORE_PROJECT_FORM_PART_PREVIEW] = new FormPartPreviewVisualStore(obj[STORE_PROJECTS_ROOT_UI], service, projectsService);
}

export type FormTypesStores = {
    [STORE_PROJECT_FORM_TYPES]?: FormTypesStore;
    [STORE_PROJECT_FORM_TYPES_VISUAL]?: FormTypesVisualStore;
    [STORE_PROJECT_FORM_PART_PREVIEW]?: FormPartPreviewVisualStore
};