import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { STORE_TEST_PROJECT, STORE_TEST_PROJECT_DASHBOARD, STORE_TEST_PROJECT_WIZARD } from '../stores/constants';
import { TestProjectStores } from '../stores';
import { TestProjectBaselinesList } from '../components';


const TestProjectBaselinesPage: React.FC<TestProjectStores> = ({projectTestProjectsUI, projectTestProjectsWizardUI, projectTestProjectsDashboardUI}) => {
    if (!projectTestProjectsUI || !projectTestProjectsWizardUI || !projectTestProjectsDashboardUI) {
        return null;
    }

    return(
        <TestProjectBaselinesList store={projectTestProjectsDashboardUI} wizardStore={projectTestProjectsWizardUI} />
    );
};

export default inject(STORE_TEST_PROJECT, STORE_TEST_PROJECT_WIZARD, STORE_TEST_PROJECT_DASHBOARD)(observer(TestProjectBaselinesPage));