import * as React from 'react';
import ProjectsList from '../components/ProjectsList';
import { inject, observer } from 'mobx-react';
import { RootStores } from '../modules/common/stores';
import { STORE_PROJECTS, STORE_ROUTER, STORE_PROJECTS_ROOT_UI } from '../modules/common/constants';
import { AuthConsumer } from '../modules/authorization/AuthContext';
import { HasPermission } from '../modules/authorization/components/HasPermission';
import { AppPermissions } from '../modules/authorization/Permissions';
import { STORE_PROJECT_FORM, STORE_PROJECT_IMPORT } from '../modules/project_management/constants';
import { ProjectStores } from '../modules/project_management/stores';
import AddProjectDialog from '../modules/project_management/components/AddProjectDialog';
import ProjectImportDialog from '../modules/project_management/components/ProjectImportDialog';
import SideMenuHeaderControls from './SideMenuHeaderControls';
import { Divider } from 'antd';

type Props = RootStores & ProjectStores;

type State = {
    collapsed: boolean
};

@inject(STORE_PROJECTS, STORE_PROJECT_FORM, STORE_ROUTER, STORE_PROJECT_IMPORT, STORE_PROJECTS_ROOT_UI)
@observer
export default class ProjectsSideMenu extends React.Component<Props, State> {
    render() {
        const projectsStore = this.props.projects!;
        const routerStore = this.props.router!;
        const projectImportStore = this.props.projectImport!;
        const projectsVisualStore = this.props.projectsRootUI!;

        const alphaVersion = () => {
            return (
                <div className="alpha-version-wrapper">
                    <Divider/>
                    <div style={{color: '#ffffff59', margin:'10px 0 12px 18px', textAlign: 'center'}}>
                        Alpha version: {process.env.REACT_APP_VERSION ?? '0.0.0'}
                    </div>
                </div>
            );
        };

        return (     
            <>      
                <AuthConsumer>
                    {({permissions}) => ( 
                        <>
                            <SideMenuHeaderControls routerStore={routerStore} projectsStore={projectsStore}/>
                            <Divider style={{marginTop: 0}}/>
                            <ProjectsList permissions={permissions}/> 
                            <HasPermission 
                                permissionClaim={AppPermissions.CanAddEditDeleteAssignProject}
                                yes={() => (
                                    <div className="projects-side-menu-footer">
                                        <AddProjectDialog projectsStore={projectsStore} />
                                        <ProjectImportDialog store={projectImportStore} projectsStore={projectsStore} projectsVisualStore={projectsVisualStore} />
                                        {alphaVersion()}
                                    </div>
                                )}
                                no={() => (
                                    <div className="projects-side-menu-footer">
                                        {alphaVersion()}
                                    </div>)}
                            />
                        </>   
                    )}
                </AuthConsumer>
            </>
        );
    }
}
