import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RootStores } from '../modules/common/stores';
import { STORE_PROJECTS, STORE_ROUTER } from '../modules/common/constants';
import { AuthConsumer } from '../modules/authorization/AuthContext';
import SideMenuHeaderControls from './SideMenuHeaderControls';
import { Divider, Menu, Tooltip } from 'antd';
import { hasPermission } from '../modules/authorization/components/HasPermission';
import { AppPermissions } from '../modules/authorization/Permissions';
import { SecurityPagesNavigation } from '../modules/administration/routes';
import { IdcardOutlined } from '@ant-design/icons';

type Props = RootStores;

type State = {
    collapsed: boolean;
    selectedItemKey: string;
    selectedKeys: string[]
};

@inject(STORE_ROUTER, STORE_PROJECTS)
@observer
export default class AdministrationSideMenu extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            collapsed: this.props.router!.sideCollapsed,
            selectedItemKey: '',
            selectedKeys: ['']
        };
    }

    componentDidMount = () => {
        const location = window.location.href;
        if (location.includes('user-management')) {
            this.setState({selectedKeys: ['userManagement']});
        } else if (location.includes('roles-management')) {
            this.setState({selectedKeys: ['rolesManagement']});
        } else {
            this.setState({selectedKeys: ['projectAssignment']});
        }
    };

    render() {
        const routerStore = this.props.router!;
        const projectsStore = this.props.projects!;

        const onSelectMenuItem = (key: string, type: string) => {
            this.setState(s => ({ ...s, selectedKeys: [key], selectedItemKey: key }));
            if (type != null) {
                switch (type) {
                case 'userManagement':
                    routerStore.pushToHistory(SecurityPagesNavigation.UserManagement);
                    return;
                case 'projectAssignment':
                    routerStore.pushToHistory(SecurityPagesNavigation.ProjectAssignment);
                    return;
                case 'rolesManagement':
                    routerStore.pushToHistory(SecurityPagesNavigation.RolesManagement);
                    return;
                default: return;
                }
            }
        };
    
        // eslint-disable-next-line max-len
        const menuItem = (type: string, icon: React.ReactElement, title: string, enabled: boolean, style: object | undefined = undefined, appRoles?: string[], permissionClaim?: AppPermissions ) => {
            if (!appRoles || hasPermission(appRoles, permissionClaim!)) {
                return (
                    <Menu.Item 
                        className={this.state.selectedItemKey === type ? 'selected' : ''}
                        key={type} 
                        disabled={!enabled} 
                        onClick={() => onSelectMenuItem(type, type)}
                    >
                        <span style={style}>
                            <Tooltip title={title} placement="right">
                                {icon}
                            </Tooltip>
                            <span>
                                {title}
                            </span>
                        </span>
                    </Menu.Item>);
            } else {
                return (
                    <></>
                );
            }
        };
    
        return (     
            <>      
                <AuthConsumer>
                    {({permissions}) => (
                        <>
                            <SideMenuHeaderControls routerStore={routerStore} projectsStore={projectsStore}/>
                            <Divider style={{marginTop: 0}}/>
                            <Menu
                                mode="inline"
                                selectedKeys={this.state.selectedKeys}
                                style={{ overflow: 'hidden auto', borderRight: 0, maxHeight: 'calc(100vh - 195px)' }}
                                inlineIndent={18}
                                /* eslint-disable max-len */
                            >
                                {menuItem('userManagement', <i className="alpha-icon md members-w-icon" />, 'Manage users', true, undefined, permissions, AppPermissions.CanAddEditDeleteAssignProject)}
                                {menuItem('projectAssignment', <i className="alpha-icon md projects-w-icon" />, 'Project assignments', true, undefined, permissions, AppPermissions.CanAddEditDeleteAssignProject)}
                                {menuItem('rolesManagement', <IdcardOutlined className="alpha-icon md" style={{marginLeft: 2}}/>, 'Roles management', true, undefined, permissions, AppPermissions.CanAddEditDeleteAssignProject)}
                            </Menu>
                        </>
                    )}
                </AuthConsumer>
            </>
        );
    }
}
