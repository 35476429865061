import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { STORE_TEST_PROJECT, STORE_TEST_PROJECT_WIZARD } from '../stores/constants';
import { TestProjectStores } from '../stores';
import { TestProjectsList } from '../components';


const TestProjectsPage: React.FC<TestProjectStores> = ({projectTestProjectsUI, projectTestProjectsWizardUI}) => {
    if (!projectTestProjectsUI || !projectTestProjectsWizardUI) {
        return null;
    }

    return(
        <TestProjectsList store={projectTestProjectsUI} wizardStore={projectTestProjectsWizardUI}/>
    );
};

export default inject(STORE_TEST_PROJECT, STORE_TEST_PROJECT_WIZARD)(observer(TestProjectsPage));