import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { TestProjectRunTopicPreview } from '../components';
import { TestProjectStores } from '../stores';
import { STORE_TEST_PROJECT_RUN_TOPIC_PREVIEW } from '../stores/constants';

const TestProjectBaselinesPage: React.FC<TestProjectStores> = ({projectTestProjectsRunTopicPreviewUI}) => {
    return (
        <TestProjectRunTopicPreview store={projectTestProjectsRunTopicPreviewUI!} />
    );
};

export default inject(STORE_TEST_PROJECT_RUN_TOPIC_PREVIEW)(observer(TestProjectBaselinesPage));