import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { TestProjectStores } from '../stores';
import { STORE_TEST_PROJECT_DASHBOARD } from '../stores/constants';
import TestProjectRunResultList from '../components/TestProjectRunResultsList';


const TestProjectRunResultPage: React.FC<TestProjectStores> = ({projectTestProjectsDashboardUI}) => {
    if (!projectTestProjectsDashboardUI) {
        return null;
    }

    return (
        <TestProjectRunResultList store={projectTestProjectsDashboardUI} />  
    );
};

export default inject(STORE_TEST_PROJECT_DASHBOARD)(observer(TestProjectRunResultPage));